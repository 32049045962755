.main-content-title{
    color:  var(--primary);
  }
  .Mobile-Image-div{
  display: flex;
  justify-content: center;

  }
  .main-container{
    padding-top: 80px ;
    padding-bottom: 80px ;
  
  }
  .main-container-mobile{
    padding-top: 30px ;
    padding-bottom: 30px ;
  }
  .main-content-text{
    padding-top: 8px ;
    padding-bottom: 8px ;
  }
  @media screen and (max-device-width:990px), screen and (max-width:990px) {
    .main-container{
        padding-top: 10px ;
        padding-bottom: 80px ;
        padding-left: 55px;
        padding-right: 55px;
      }
  }