.mainDiv {
    background-color: #FFFFFF;
    display: flex;
    padding: 0px 40px;
}
marquee{
  color:#005695 !important;
  font-size: 16px!important;
  font-weight: 500!important;
  line-height: 18px!important;

  text-decoration: none;
  }

a.underline.active.nav-link{
  background-color: var(--default);
}

.logo {
    width: 30%;
}
.Menus{
    width: 70%;
}
a.nav-link {
    color:rgba(0, 86, 149, 1)!important;
    font-size: 16px!important;
    font-weight: 500!important;
    line-height: 18px!important;
    padding: 0px 32px!important;
    padding: 9px 34px!important;
    border-radius: 34px;
    overflow: hidden;
    text-decoration: none;
}
a.nav-link:hover {
    color: #005695!important;
    padding: 9px 34px!important;
    border-radius: 34px;
    overflow: hidden;
    background-color: #E5EEF4;
    
}

a.nav-link:focus {
    text-decoration: underline;
   
    border: black !important;
}
a.nav-link:active {
    text-decoration: underline;
   
}
img.logo {
    height: 100%;
    width: 50%;
}
.ml-auto.nav {
    padding: 14px;
    text-align: end;
}


.butnDiv {
    text-align: center;
    margin-top: 24px;
}
.logoDiv {
    text-align: center;
}
.logoDiv img {
  height: 80px;
  max-width: 212px;
  width: 100%;
  margin: auto;
  margin-top: -34px;
  padding: 10px;
}
.navbar-expand {
    flex-wrap: nowrap;
    justify-content: end !important;
}
/* .offcanvas-header{
  display: flex;

    justify-content: end !important;
} */

.butn{
  text-align: center;
}
.butn img{
  width: 100%;
  max-width: 52px;
  height: 78px;
}


.offcanvas.offcanvas-start.show {
  width: 100%;
}

img.logodive {
  width: 100%;
  max-width: 216px;
}
.link_name{
  color: rgba(0, 86, 149, 1) !important;
}

#pot {
  top: 0%;
  position: absolute;
  animation: run 0.1s linear;
  right: 0%;
}

@keyframes run {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 20px);
  }
}







/* header code */








  /* couttom pakages css */
 
  
  .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    max-width: 908px;
    width: 100%;
  }
  
  /* header css*/
  
 

  
  .menu_item_name {
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-right: 103px;
  }

  .link_name {
    max-width: 455px;
    width: 100%;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    color: rgba(0, 86, 149, 1);
    text-decoration: none;
    padding: 15px 0px;
  
  }
  .link_name_digital {
    max-width: 455px;
    width: 100%;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    text-decoration: none;
    color: rgba(0, 86, 149, 1);
    padding: 15px 0px;
  }

  .header_drop_down_link {
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    padding: 0px 20px !important;
  }
  .dropdown_link_name {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #252525;
    padding: 15px 0px;
    border-bottom: 1px solid #bfbfbf;
    max-width: 455px;
    width: 100%;
    margin-bottom: 0px;
    cursor: pointer;
  }

 

  @media only screen and (max-width: 991px) {
    .offcanvas.show:not(.hiding),
    .offcanvas.showing {
      width: 100% !important;
    }
    .menu_item_name {
      margin-right: 5%;
      width: 100%;
    }
    .whatsappp_btn{
      
      right: 90px;
      bottom: 18px;
  
    }
  }
  
  
  /* mob view */
  @media only screen and (max-width: 600px) {
    .link_name {
      font-weight: 400;
      font-size: 34px;
      line-height: 41px;
      padding: 8px 0px;
      color: rgba(0, 86, 149, 1);
    }
    .menu_main{
      padding: 0px 10px 10px 5%;
      margin-top: -15px;
    }
    .link_name_digital {
      font-weight: 400;
      font-size: 34px;
      line-height: 41px;
      padding: 8px 0px;
      color: rgba(0, 86, 149, 1);
    }
    .mob_img_Services {
      display: block;
    }
    .offcanvas.show:not(.hiding),
    .offcanvas.showing {
      width: 100% !important;
      background-color:rgba(248, 248, 248, 1);
      ;
    }

    .dropdown_link_name {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      padding: 11px 0px;
    }
   
    .header_top {
      padding: 15px 30px;
    }

  }
  @media only screen and (max-width: 360px) {
    .link_name {
      font-size: 28px;
    }
   
    .dropdown_link_name {
      font-size: 17px;
    }
    .connect_name {
      font-size: 18px;
    }
    .link_name_digital {
      font-size: 28px;
  
      padding: 5px 0px;
    }
  }
  

