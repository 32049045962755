.main-conatiner {
 background-color:#E8F0FB ;
 height:297px;
 padding-top: 69px;
 overflow: hidden;
  }
  .social-buttons {
    padding-top: 58px;
   display: flex;
    justify-content: center;
    gap:2rem;
  }
  .footer-content-2 {
   display: flex;
   justify-content: center;
   gap:2rem;
   padding-top: 20px;
 
  }
  .footer-content-1 {
    /* display: flex; */
    justify-content: flex-start;
  
  
   }
   .footer-content-3 {
    display: flex;
    justify-content: flex-end;
  
  
   }
  .footer-text {
   font-size: 14px;
   font-weight: 400;
   color:#005695;
   text-decoration: underline;
   text-underline-offset: 8px;
   line-height: 17.07px;
  }
  .footer-text1 {
    font-size: 12px;
    font-weight: 400;
    color:#005695;
    text-decoration: underline;
    text-underline-offset: 8px;
    line-height: 223%;
   }
  .footer-btn{
    padding:15px 45px;
    background-color: #005695;
    color:#FFFFFF;
    width:180px;
    border-radius: 100px;
  }
  .footer-last-container{
    background-color: #005695;
   

  }
  .footer-div{
    text-align: center;
  }
  .footer-subhead-text{
    text-align: center;
    color:#FFFFFF;
 
   padding-top: 15px;
   
    
    

  }
  .footer-content-1-mobile{
    display: flex;
    justify-content: flex-start;
  }
  .footer-content-2-mobile{
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 600px) {
    .footer-content-1 {
      /* display: flex; */
    text-align: center;
    justify-content: center;
    
     }
  }
  /* @media screen and (max-device-width:1220px), screen and (max-width:1220px) {
    p {
      font-size: 13px  !important;
      font-weight:lighter;
      font-family:'Montserrat-Medium';
    }
  } */
  /* @media screen and (max-device-width:980px), screen and (max-width:980px) {
    p {
      font-size: 10px  !important;
      font-weight:lighter;
      font-family:'Montserrat-Medium';
    }
  } */