.Section-1-Content{
    color: #005695;
    padding-top: 18%;
    width: 50%;
    }
    .Section-1-Image{
    width:auto;
    }
    .naibDiv{
       
        background-color: #F2F7FA;
        color:"#005695";
       
    }
    .Section_main_Dev{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* padding-top: 120px;
        padding-bottom: 120px; */
    }
    .section-1-container{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    .section-1-container img {
        width: 100%;
    }
    .Section-main-mobile{
       text-align: center;
       padding-left: 93px;
       padding-right: 93px;
           padding-top: 73px;
           padding-bottom: 73px;
       }
       .Section-mobile-image{
       
           max-width: 192px;
           
          }
          @media screen and (max-width: 995px) {
      .Section-1-Content{
        padding-top: 8%;
        padding-right: 2%;
      }

        }