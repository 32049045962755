/* custom-bootstrap.css */

/* Override font family */


@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../src/Components/Assets/font/Montserrat-Bold.ttf') format('opentype');
  font-weight: 700;
 
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../src/Components/Assets/font/Montserrat-SemiBold.ttf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../src/Components/Assets/font/Montserrat-Regular.ttf') format('opentype');
  font-weight: 500;
 
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../src/Components/Assets/font/Montserrat-Medium.ttf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat-Black';
  src: url('../src/Components/Assets/font/Montserrat-Black.ttf') format('opentype');
 
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family:'Montserrat';
}


/* Override font size */
h1 {
  font-size: 66px  !important;
  font-weight: 700;
  line-height: auto;
  font-family: 'Montserrat-Bold';
  
}
h2 {
  font-size: 42px  !important;
  font-weight: bold;
  line-height: auto;
  font-family: 'Montserrat-Bold';
}
h3 {
  font-size: 40px !important;
  font-weight: bold;
  line-height: auto;
  font-family: 'Montserrat-Bold';
}
h4 {
  font-size: 32px  !important;
  font-weight: bold;
  line-height: 135%;
  font-family: 'Montserrat-Bold';
}
h5 {
  font-size: 30px  !important;
  font-weight: bold;
  line-height: auto;
  font-family: 'Montserrat-Bold';
}
h6 {
  font-size: 18px  !important;
  font-weight: 600;
  line-height: auto;
  font-family: 'Montserrat-SemiBold';
}
p {
  font-size: 16px  !important;
  font-weight:lighter;
  font-family:'Montserrat-Medium';
}
ul {
  font-size: 32px  !important;
font-weight: 600;
line-height: 39px;
  font-family:'Montserrat-Regular';
  color: var(--primary);
}
.context-text{
  padding-top:8px;
  padding-bottom:8px;

}
.primaryText{
  color: var(--primary);
}
.defaultText{
  color: var(--default);
  
}

@media screen and (max-device-width:900px), screen and (max-width:900px) {
  ul {
    font-size: 20px !important;
  font-weight: 600;
  line-height: 39px;
    font-family:'Montserrat-Regular';
    color: var(--primary);
  }
  .context-text{
    padding-top:4px;
    padding-bottom:4px;
  
  }
  p{
    font-size: 12px  !important;
    font-weight:lighter;
    font-family:'Montserrat-Medium';
  }
  h3 {
    font-size: 30px !important;
  }
  h5 {
    font-size: 26px  !important;
  }
}
@media screen and (max-device-width:990px), screen and (max-width:990px) {
  .mainSection{
    padding-left: 55px;
    padding-right: 55px;
  }
}

.primaryText{
  color: var(--primary);
}