.contactpage {
    background: #F8F8F8;
}
.contactForm {
    padding: 83px 0;
}
.contactHeading {
    font-weight: 700;
    font-size: 66px;
    line-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #005695;
    padding-top: 10px;
}
.contactPara {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #005695;
    padding: 20px 0 30px 0;
}
.finputfield>input, .finputfield>textarea {
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #005695;
}
.finputfield>textarea {
    height: 112px;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #005695;
}
.finputfield>input::placeholder, .finputfield>textarea::placeholder {
    color: #005695;
}

/* Heading 2 style */
.contactForm {
    padding: 60px 0 40px 0;
}
.contactDetail {
    background: #FFF;
    padding-bottom: 5rem;
}
.heading2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #005695;
    padding: 3rem 0 2rem 0;
}
.h2contant {
    display: flex;
    flex-direction: column;
    justify-content: start;

}
.h2contant>span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #005695;
    padding-bottom: 10px;
}
.h2contant>h6 {
    line-height: 145%;
    color: #005695;
}
.cImage img {
    width: 100%;
    display: none;
}
@media screen and (max-width: 900px) {
    .contactHeading {
        font-size: 42px;
        line-height: 100%;
    }
    .contactPara {
        font-size: 16px;
        line-height: 20px;      
    }
    .h2contant {
        margin: 15px 0;
    }
    .heading2 {
        font-size: 30px;
        line-height: 37px;
        padding: 3rem 0 1rem 0;
    }
    .cImage img {
        display: block!important;
    }
}