.gradient-Text{
 
    background: linear-gradient(93.96deg, #005695 5.6%, #E461FB 89.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding-top: 60px;
}
.transparent-Text {
    text-align: center;
    background: linear-gradient(93.96deg, #005695 5.6%, #2BFD86 89.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    opacity: 0.1;
    padding-top: 20px;
    margin: 0;
}
.section4 {
    background-color: #FFFFFF;
}
.text_main {
    width: 100%;
    max-width: 900px;
    padding: 0px 20px;
}

.solution{
    background: linear-gradient(93.96deg, #005695 5.6%, #E461FB 89.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.section-2-img{
    width: 100%;
}
.section-2-img img{
    width: 100%;
    max-width: 900px;
    height: 100%;
padding: 60px 10px;
}



.image-banner{
width: 100%;
max-width: 2660px;
}

.image-banner img{
    width: 100%;
    }
.img-banner-mobile{
    width: 100%;
}
h2.heading_h2 {
    background: linear-gradient(93.96deg, #005695 5.6%, #E461FB 89.81%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.heading_Mob {
    width: 100%;
    text-align: center;
}
.text_Div{
width: 100%;
    padding: 0px 55px;
    text-align: center;
}
.img-banner-mobile img{
    width: 100%;
    max-width: 913px;
}
.banner-content{
align-items: center;
top: 50%;
bottom: 50%;
  
}
.section2{
    background-color: #F2F7FA;
    color:"#005695";
    padding-top: 170px;
    padding-bottom: 170px;
}
.section-1{
    color:"#005695";
   
}
.section-container{
    display: flex;
    justify-content: space-between;
}
.Section-1-Content{
    color: #005695;
    padding-top: 18%;
    width: 50%;
}
.Section-1-Image{
    width: auto;
}
.naibDiv{
    background-color: #F2F7FA;
    color:"#005695";
}
.Section-main{
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.Section-main-mobile{
 padding-left: 10px;
 padding-right: 10px;

    padding-top: 120px;
    padding-bottom: 120px;
}
.Section-mobile-image{

    max-width: 192px;
    
   }
.section-1-container{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.section-1-container img {
    width: 100%;
    margin-left: 40px;
}

.text-line>.description {
font-weight: 400;
font-size: 22px;
line-height: 135%;
color: #005695;
}
.Section-Three{
    text-align: center;
    display: flex;
    justify-content: center;
}

.homelastImg {
    display: flex;
    align-items: center;
    height: 795px;
    margin: auto;
    padding: 0 100px;
}
.homelastImg img {
    width: 100%;
}

/* About section */
.centerImg img {
    width: 100%;
}
.rightheading {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}
.rightDescription span {
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #005695;
}
.rightTop {
    padding-bottom: 20px;
}
@media screen and (max-width: 900px) {
    .homelastImg {
        justify-content: center;
        height: 621px;    
    }
    .homelastImg img {
        width: 268px;
        height: 415px;
    } 
    .Section-main{
        display: block;
        padding: 0 20px;
    }
    .Section-1-Content {
        width: 100%;
    }
    .section-1-container {
        padding: 10%;
        width: 100%;
    }
    .text-line {
        margin: auto;
    }
    .text-line>.description {
        font-size: 16px;
    }
    .text-line>.mobTitle {
        font-size: 24px!important;
        line-height: 30px;
    }
    /* About section */
    .centerImg img {
        padding: 40px 0 30px 0;
    }
    .rightTop {
        padding: 0;
    }
}


.main_Div_Dev {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 50px 60px;
}
.main_Div_Dev_mobile {
    width: 100%;
    display: flex;
    align-items: center;
   padding-top: 40px;
   padding-bottom: 40px;
}
.div_Dev_1 {
    width: 50%;
}
.div_Dev_2 {
    width: 50%;
}
.div_Dev_4 {
    width: 100%;
}
.div_Dev_2 img {
    width: 100%;
}
button.btn_blog_Dev {
    border: none;
    padding: 14px 27px;
    border-radius: 74px;
    color: #005695;
    margin-bottom: 27px;
    font-family:'Montserrat-Medium';
}
h4.dev_heading {
    color: #005695;
}
.div_Dev_1_mob{
    width: 100%;
    max-width: 747px;
    padding: 52px 72px;
}
h5.dev_heading {
    color: #005695;
    padding-top: 34px;
    padding-bottom: 34px;
}

@media only screen and (max-width: 480px)  {
    .div_Dev_1_mob{
        width: 100%;
        max-width: 686px;
        padding: 52px 8px 52px 44px;
    }
 
    button.web_Dev {
        padding: 5px 6px;
        border: none;
        font-size: 9px;
        border-radius: 6px;
        background-color: #b7a5db;
        color: white;
        margin-bottom: 2px;
    }
   
}
.second_div_Dev {
    width: 100%;
    display: flex;
    max-width: 800px;
    align-items: center;
    border: 1px solid #005695 ;
    border-radius: 10px;
    padding: 11px 13px;
}
.second_main_div_Dev {
padding: 0px 15px 40px 15px;
}
.second_Div_section_1 {
    width: 50%;
    
    max-width: 414px;
}
.second_Div_section_2 {
    width: 50%;
    max-width: 334px;
    padding: 0px 13px;
}
.second_Div_section_1 img{
width:100%;
max-width: 414px;
height: 100%;
}
h6.heading_Div {
    width: 100%;
    max-width: 414px;
    color: #005695;
    font-size: 12px!important;
}
@media only screen and (min-width: 480px)  {

    h6.heading_Div {
        width: 100%;
        max-width: 267px;
    }
    h6.heading_Div {
        width: 100%;
        font-size: 21px!important;
        max-width: 414px;
        color: #005695;
    }
   
}

button.web_Dev {
    padding: 5px 15px;
    border: none;
    border-radius: 6px;
    background-color: #b7a5db;
    color: white;
}

button.web_Dev_dev{
    padding: 5px 15px;
    border: none;
    border-radius: 6px;
    background-color: #b7a5db;
    color: white;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}
h5.heading_Div_Dev {
    width: 100%;
    max-width: 614px;
    color: #005695;
    font-size: 22px !important;
    margin-left: 15px;
}
.about-us-box{
    padding-top:34px ;
    padding-bottom: 34px;
}