.our-clients-head{
    padding-top:34px ;
    padding-bottom: 34px;
}
.ourClientBox{
padding-top: 100px;
padding-bottom: 100px;
}
.image-tags{
padding-top: 77px;
padding-bottom: 77px;
}
.tagsImage{
    background: linear-gradient(white, white) padding-box,
                linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    width: 160px;
height: 100px;
}
 .module {
    background: white;
    padding: 2rem;
  }

  .btn-gradient-2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: darkblue;
    padding: 12px;
  }
 .parent-mobile{
  text-align: center;
 }
  .btn-gradient-2 {
    background: linear-gradient(white, white) padding-box,
                linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .tags-image{
    max-width:121px;
  }
  @media screen and (max-device-width:1400px), screen and (max-width:1400px) {
    .btn-gradient-2 {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      color: darkblue;
      padding: 4px;
    }
   
    .btn-gradient-2 {
      background: linear-gradient(white, white) padding-box,
                  linear-gradient(to right, darkblue, darkorchid) border-box;
      border-radius: 10px;
      border: 1px solid transparent;
      margin-top: 20px;
      margin-bottom: 20px;
      gap:2rem;
    }
    .tags-image{
      max-width:161px;
    }
  }
  @media screen and (max-device-width:900px), screen and (max-width:900px) {
    .btn-gradient-2 {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      color: darkblue;
      padding: 8px;
      justify-content: space-between;
    }
   
    .btn-gradient-2 {
      background: linear-gradient(white, white) padding-box,
                  linear-gradient(to right, darkblue, darkorchid) border-box;
      border-radius: 10px;
      border: 1px solid transparent;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 10px;
    }
    .tags-image{
      max-width:121px;
    }
  }